<template>
    <div class="attribute_bar show" :class="{fixed : isFixed}" v-if="modelValue">
        <h3>{{ title }} <button @click="closeModal()" v-if="isCloseButton"><i class="fas fa-times"></i></button></h3>
        <div class="toggle_btn">
            <a v-if="allAttr" @click="openAllGrp" class="toggle_btn">Open All</a>
            <a v-else @click="closeAllGrp" class="toggle_btn">Close All</a>
        </div>
        <div class="attribute_grp" v-for="(attributeType, at) in attributeTypes" :key="at">
            <h4 @click="accFilter($event)">{{ attributeType.type }}<i class="fas fa-chevron-down"></i></h4>
            <ul>
                <li v-for="(attribute, a) of attributeType.attributes" :key="a" @click="toggleAttributeSelect(attribute.id)">
                    <label class="checkbox">
                        <span :class="{'active' : selectedAttibutes && selectedAttibutes.length ? selectedAttibutes.includes(attribute.id) : false}"><i class="fas fa-check"></i></span>
                        {{ attribute.name }}
                    </label>
                    <button type="button" class="pointer" @click="handleDeleteWorkoutAttribute(attribute)" v-if="!attribute.is_default"><i class="fas fa-trash danger"></i></button>
                </li>
            </ul>
        </div>
        <a v-if="!isAddAttribute" type="button" class="add_attribute" href="https://thrivecoach.io/request-exercise/" target="_blank">Exercise Request</a>
        <button v-if="isAddAttribute" type="button" class="add_attribute" @click="newAttribute = true;"><i class="fas fa-plus"></i>Add Attribute</button>
    </div>

    <new-attribute v-model="newAttribute" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    const NewAttribute = defineAsyncComponent(() => import('@/pages/workout/components/NewAttribute'))

    export default {
        name: 'Workout Attribute ',

        data () {
            return {
                form: {
                    name: '',
                    id: '',
                },
                newAttribute: false,
                loader: false,
                allAttr: 1
            }
        },

        props: {
            modelValue: Boolean,
            title: String,
            selectedAttibutes: {
                type: Array,
                default: () => {}
            },
            toggleAttributeSelect: {
                type: Function,
                default: () => {}
            },
            isFixed: {
                type: Boolean,
                default: false,
            },
            isAddAttribute: {
                type: Boolean,
                default: false,
            },
            isCloseButton: {
                type: Boolean,
                default: true,
            },
        },

        emit: ['update:modelValue'],

        components: {
            NewAttribute
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();

                    if (!Object.keys(vm.attributeTypes).length)  {
                        vm.getWorkoutAttributeTypes();
                    }
                }
            }
        },

        computed: mapState({
            attributeTypes: state => state.workoutModule.workoutAttributeTypes,
            workoutAttributeTypesLoader: state => state.workoutModule.workoutAttributeTypesLoader,
        }),

        mounted () {
            const vm = this;

            if (!Object.keys(vm.attributeTypes).length)  {
                vm.getWorkoutAttributeTypes();
            }
        },

        methods: {
            ...mapActions({
                getWorkoutAttributeTypes: 'workoutModule/getWorkoutAttributeTypes',
                getWorkoutBlocks: 'workoutModule/getWorkoutBlocks',
                deleteWorkoutAttribute: 'workoutModule/deleteWorkoutAttribute',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            accFilter (ev) {
                const vm = this;

                let el = ev.currentTarget.closest('.attribute_grp');

                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                    vm.allAttr = 1;
                } else {
                    el.classList.add('active');
                    vm.allAttr = 0;
                }
            },

            openAllGrp () {
                const vm = this;

                let allParentElm = document.querySelectorAll('.attribute_grp');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('active');
                }

                vm.allAttr = 0;
            },

            closeAllGrp () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.attribute_grp');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('active');
                }

                vm.allAttr = 1;
            },

            handleDeleteWorkoutAttribute (attribute) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this attribute`, 'Delete');

                options.preConfirm = function () {
                                        const params = { id: attribute.id, workout_attribute_type_id: attribute.workout_attribute_type_id }

                                        return vm.deleteWorkoutAttribute(params).then((result) => {
                                            if (result) {
                                                vm.getWorkoutAttributeTypes();
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            resetForm () {

            }
        }
    }
</script>

<style scoped>
    .attribute_bar{
        padding: 15px 0;
        background: #fff;
        border-left: 1px solid #e9e9e9;
        width: 230px;
        max-height: 100vh;
        overflow-y: overlay;
        position: relative;
        margin-right: -230px;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction:column;
    }
    .attribute_bar.show{
        margin-right: 0;
    }
    .attribute_bar::-webkit-scrollbar{
        width: 4px;
    }
    .attribute_bar::-webkit-scrollbar-thumb{
        border-radius: 2px;
        background: #cacaca;
    }
    .attribute_bar h3{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        padding: 0 20px 5px 20px;
        display: flex;
        align-items: center;
    }
    .attribute_bar h3 button{
        font-size: 13px;
        color: #999;
        margin-left: auto;
        cursor: pointer;
    }
    .attribute_bar ul li{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 10px 15px;
    }
    .attribute_bar ul li:last-child{
        margin-bottom: 15px;
    }
    .attribute_bar ul li label.checkbox, .dropdown_wpr ul li label.checkbox{
        justify-content: flex-start;
        gap: 10px;
    }
    .filters .attribute_bar{
        position: fixed;
        right: -230px;
        top: 0;
        height: 100vh;
        overflow-y: scroll;
        transition: all 0.3s ease-in-out;
    }
    .filters .attribute_bar.show{
        right: 0;
    }
    .attribute_bar ul li label.checkbox span.active i, .dropdown_wpr ul li label.checkbox span.active i{
        transform: scale(1);
        opacity: 1;
    }
    .attribute_bar ul li {
        display: flex;
        justify-content: space-between;
    }
    .attribute_grp{
        border-top: 1px solid #f5f5f5;
    }
    .attribute_grp h4{
        padding: 12px 15px;
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .attribute_grp h4 a{
        color: #5a5a5a;
        margin-right: 15px;
        font-size: 11px;
        display: flex;
        align-items: center;
    }
    .attribute_grp h4 a.pinned{
        color: #2f7eed;
    }
    .attribute_grp h4 > i{
        margin-left: auto;
        font-size: 11px;
        color: #999;
        transition: all 0.3s ease-in-out;
    }
    .attribute_grp ul{
        padding: 0 15px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .attribute_grp.active ul{
        max-height: 10000px;
    }
    .attribute_grp.active h4 > i{
        transform: rotate(-180deg);
    }
    .toggle_btn{
        display: flex;
        justify-content: flex-end;
        padding: 6px 20px 12px 20px;
    }
    .toggle_btn a{
        padding: 4px 10px;
        background: #f5f5f5;
        font-size: 10px;
        line-height: 12px;
        color: #121525;
        font-weight: 500;
        display: block;
        border-radius: 10px;
        cursor: pointer;
    }
    .add_attribute{
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 10px 20px;
        background: #2f7eed;
        color: #fff;
        border-radius: 5px;
        margin: auto 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        gap:7px;
        cursor: pointer;
    }
    .add_attribute i{
        font-size: 9px;
    }

    .attribute_bar.fixed{
        position: fixed;
        right: -230px;
        top: 0;
        bottom: 0;
        z-index: 13;
        transition: all 0.3s ease-in-out;
    }
    .attribute_bar.fixed.show{
        right: 0;
    }

    a.add_attribute {
        text-decoration: none;
    }

    @media(max-width: 1350px){
        .attribute_bar.show{
            position: fixed;
            right: 0;
            height: 100dvh;
            z-index: 11;
        }
      .attribute_bar.fixed.show {
        z-index: 13;
      }
    }
</style>
